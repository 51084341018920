import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { get } from 'lodash';
import parse from 'html-react-parser';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';

// Componennts
import AnimateIn from '../components/animate-in';
import Layout from '../components/layout';
import LeftArrowIcon from '../assets/svgs/chevron-left.svg';
import ActionPanel from '../components/action-panel';

// Utils
import ComponentMap from '../utils/component-map';
import SeoUtils from '../utils/seo-utils';

import '../styles/templates/article-details.scss';

const ArticleDetail = ({ pageContext, style, innerRef, location, data }) => {
  const pageData = get(pageContext, 'articleData');
  const queryData = get(pageData, 'content');
  const pageUrl = get(data, 'site.siteMetadata.baseUrl')
    ? `${get(data, 'site.siteMetadata.baseUrl')}${location.pathname}`
    : location.href;

  // Component Props
  const ComponentProps = {
    title: get(pageData, 'title'),
    articleSlug: get(pageData, 'selectArticleTagCategory.slug'),
    articleTagTitle: get(pageData, 'selectArticleTagCategory.articleTagTitle'),
    articleCreatedDate: get(pageData, 'meta.createdAt'),
    articleSubTitle: get(pageData, 'subtitle'),
    articleImage: get(pageData, 'featuredImage.url'),
    articleAlt: get(pageData, 'featuredImage.alt'),
    articleTopDescription: get(pageData, 'articleTopDescription'),
    articleDescription: get(pageData, 'articleDescription'),
  };

  // Seo
  const seoTags = get(pageData, 'seoMetaTags.tags');
  const seoProps = {
    description:
      SeoUtils.extractProperty(seoTags, 'name', 'description') ||
      ComponentProps.articleSubTitle,
    twitterCardType: SeoUtils.extractProperty(seoTags, 'name', 'twitter:card'),
    cardImage:
      SeoUtils.extractProperty(seoTags, 'property', 'og:image') ||
      ComponentProps.articleImage,
  };

  // Render components based on module
  const RenderComponents = queryData
    ? queryData.map((eacModule) => {
        const label = get(eacModule, 'model.apiKey');
        const ComponentProp = {
          data: eacModule,
        };
        const eachComponent = ComponentMap(label, ComponentProp, eacModule.id);
        return eachComponent;
      })
    : [];
  return (
    <div className="article-details-page">
      <Layout title={ComponentProps.title} seoProps={seoProps}>
        <section className="Article-Banner">
          <div className="wrapper">
            <div className="back-to-list">
              <LeftArrowIcon />
              <Link to={`/${ComponentProps.articleSlug}`}>Back to list</Link>
            </div>
            <div className="banner-content">
              <div className="article-tag">
                <span className="tag">{ComponentProps.articleTagTitle}</span>
                <span>{ComponentProps.articleCreatedDate}</span>
              </div>
              <h2>{ComponentProps.title}</h2>
              <p>{parse(ComponentProps.articleSubTitle)}</p>
            </div>
          </div>
        </section>
        {ComponentProps.articleImage ? (
          <div className="featured-image">
            <div className="wrapper">
              <figure>
                <img
                  src={ComponentProps.articleImage}
                  alt={ComponentProps.articleAlt}
                  className="object-fit-image"
                />
              </figure>
            </div>
          </div>
        ) : null}
        {ComponentProps.articleDescription ? (
          <section
            className={
              ComponentProps.articleImage
                ? 'main-content'
                : 'main-content content-container'
            }
          >
            <div className="wrapper1">
              <div className="description" style={style} ref={innerRef}>
                <div className="wrapper2">
                {ComponentProps.articleTopDescription ?
                      <div className="article-top-description">
                        <div className="wrapper-top-description">
                          {parse(ComponentProps.articleTopDescription)}
                        </div>
                      </div>
                    : null
                }
                <div className="article-description">
                  <div className="wrapper">
                     {parse(ComponentProps.articleDescription)}
                  </div>
                </div>
                </div>
              </div>
              <div className="social-share" style={style} ref={innerRef}>
                <div className="wrapper">
                <h4>SHARE</h4>
                <ul className="social-share-list">
                  <li>
                    <FacebookShareButton url={pageUrl}>
                      Facebook
                    </FacebookShareButton>
                  </li>
                  <li>
                    <LinkedinShareButton url={pageUrl}>
                      LinkedIn
                    </LinkedinShareButton>
                  </li>
                  <li>
                    <TwitterShareButton url={pageUrl}>
                      Twitter
                    </TwitterShareButton>
                  </li>
                </ul>
                </div>
              </div>
            </div>
          </section>
        ) : null}
        {RenderComponents}
        <ActionPanel />
      </Layout>
    </div>
  );
};

// Site Query
export const SITE_QUERY = graphql`
  query SiteMateData {
    site {
      siteMetadata {
        baseUrl
      }
    }
  }
`;

ArticleDetail.propTypes = {
  pageContext: PropTypes.objectOf(PropTypes.object).isRequired,
  style: PropTypes.shape({
    transition: PropTypes.string,
    opacity: PropTypes.number,
    transform: PropTypes.string,
  }).isRequired,
  innerRef: PropTypes.objectOf(PropTypes.object).isRequired,
  location: PropTypes.objectOf(PropTypes.object).isRequired,
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default AnimateIn(ArticleDetail);
